import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { firebaseConfig } from './credentials';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Send',
      url: '/send',
      icon: 'cash'
    },
    {
      title: 'Receive',
      url: '/receive',
      icon: 'wallet'
    },
    {
      title: 'Exchange',
      url: '/exchange',
      icon: 'repeat'
    },
    {
      title: 'Bill Pay',
      url: '/bill-pay',
      icon: 'mail-open'
    },
    {
      title: 'Loan Offers',
      url: '/loans',
      icon: 'rocket'
    },
    {
      title: 'Services',
      url: '/services',
      icon: 'globe'
    },
    // {
    //   title: 'Other',
    //   url: '/others',
    //   icon: 'cog'
    // },
    {
      title: 'Settings',
      url: '/settings',
      icon: 'cog'
    },
    {
      title: 'Sign In',
      url: '/login',
      icon: 'log-in'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
    firebase.initializeApp(firebaseConfig);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
