import { Component, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { EventEmitter } from 'events';
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  public employees = [
    {
      image: '../../assets/gb.svg',
      name: 'English'
    },
    {
      image: '../../assets/es.svg',
      name: 'Spanish'
    },
    {
      image: '../../assets/ae.svg',
      name: 'Arabic'
    },
    {
      image: '../../assets/jp.svg',
      name: 'Japanese'
    },
  ];

  public flag = '../../assets/gb.svg';
  constructor(public popoverController: PopoverController) { }

  ngOnInit() {}


  chooseFlag(image){
    // this.flag = image;
    console.log(image);
    this.popoverController.dismiss(image);
  }

}
